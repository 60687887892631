import React, { ReactElement } from 'react'
import { Typography } from 'antd'
import { ReactComponent as FailureImage } from '../../assets/failure.svg'

interface ModalContentInterface {
  title: string
  body: string[]
  isError?: boolean
}

const ModalContent = ({ title, body, isError }: ModalContentInterface): ReactElement => {
  const { Text } = Typography

  const titleText = {
    fontFamily: 'Rubik',
    color: '#000000',
    fontSize: '20px',
    fontWeight: 400,
    margin: '20px 10px',
    display: 'block',
  }

  const bodyText = {
    fontFamily: 'Rubik',
    color: '#000000',
    fontSize: '14px',
    fontWeight: 300,
    margin: '10px 10px 20px',
    display: 'block',
  }

  return (
    <>
      {isError ? (
        <div>
          <div style={{ width: '50%', margin: '0px auto 10px auto' }}>
            <FailureImage data-testid="failure-image" style={{ height: '60%' }} />
          </div>
          <Text style={bodyText}>
            We’re sorry, we could not process your payment at this time. Our team has been notified.
            Please try again later.
          </Text>
        </div>
      ) : (
        <>
          <Text style={titleText}>{title}</Text>
          {body.map((item) => (
            <Text style={bodyText} key={item}>
              {item}
            </Text>
          ))}
        </>
      )}
    </>
  )
}

export default ModalContent
