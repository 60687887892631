import React, { ReactElement } from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import './Header.styles.scss'
import { localStorageService } from '../../services'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'
import { useAuth0 } from '../../hooks'

const Logout = (): ReactElement => {
  const { Title } = Typography

  const auth0 = useAuth0()

  const onLogout = async () => {
    trackAmplitudeEvent('clicked logout_button')
    localStorageService().clear()
    await auth0.logout()
  }
  return (
    <>
      <Link to="/" id="log-link" onClick={onLogout} data-testid="logout-link">
        <Title level={3} className="faq-text">
          Logout
        </Title>
      </Link>
    </>
  )
}

export default Logout
