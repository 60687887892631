import React, { ReactElement, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { PaymentStepLevel } from '../Dashboard/Dashboard.metadata'
import { localStorageService } from '../../services'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'

import { TransactionStatus, HowToPayTypes } from '../../utils/constant'
import * as TransferPaymentTypes from '../../redux/types/transfer-payment.type'
import {
  setStepLevel as setStepLevelFnc,
  setTransactionStatus as setTransactionStatusFnc,
} from '../../redux/actions'
import { makeTransferPayment as makeTransferPaymentFnc } from '../../redux/actions/transfer-payment'

import './PaymentConfirmation.styles.scss'

import VerificationLoader from '../../components/VerificationLoader'
import { ConfirmationPage } from '../ConfirmationPage'
import { useAuth0, useAxios } from '../../hooks'

interface IPaymentConfirmationProps {
  setStepLevel: any
  setTransactionStatus: any
  stepLevel: number
  howToPay: string
  energyCreditsCheckBoxState: boolean
  paymentSetupStep: number
  showPaymentModalFromTest?: boolean
  communityFeeGlobal: number
  energyCreditFee: number
  processingFee: number
  transactionStatus: string
}

const PaymentConfirmation = ({
  setStepLevel,
  setTransactionStatus,
  stepLevel,
  energyCreditsCheckBoxState,
  howToPay,
  paymentSetupStep,
  showPaymentModalFromTest,
  communityFeeGlobal,
  transactionStatus,
  energyCreditFee,
  processingFee,
}: IPaymentConfirmationProps): ReactElement => {
  const sumTotalWithFeeIncent = () =>
    Math.round((energyCreditFee + communityFeeGlobal) * 100 + processingFee)
  const { TRANSFER_PAYMENT_SUCCESS } = TransferPaymentTypes

  const cancel = (): void => {
    trackAmplitudeEventProperty('clicked cancel_button')
    // resetTransaction()
  }

  const makeNewPayment = () => {
    window.location.reload()
  }

  const axiosInstance = useAxios()

  const dispatch = useDispatch()

  const makeTransferPayment = (payload: { amountCents: number }) =>
    makeTransferPaymentFnc(axiosInstance, payload)(dispatch)

  const auth0 = useAuth0()

  const onLogout = async () => {
    trackAmplitudeEventProperty('clicked confirmation_page_logout_button')
    localStorageService().clear()
    await auth0.logout()
  }

  return (
    <>
      <Row>
        <ConfirmationPage
          status={transactionStatus}
          setStepLevel={setStepLevel}
          cancel={cancel}
          makeNewPayment={makeNewPayment}
          onLogout={onLogout}
        />
      </Row>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setStepLevel: (setStep: number) => dispatch(setStepLevelFnc(setStep)),
  setTransactionStatus: (transactionStatus: string) =>
    dispatch(setTransactionStatusFnc(transactionStatus)),
})

const mapStateToProps = ({
  setStepReducer,
  transferPaymentReducer,
  paymentValuesReducer,
}: {
  setStepReducer: {
    stepLevel: number
    paymentSetupStep: number
    howToPay: string
    energyCreditsCheckBoxState: boolean
    communityFeeCheckBoxState: boolean
  }
  paymentValuesReducer: {
    communityFee: number
    energyCreditFee: number
    processingFee: number
    transactionStatus: string
  }
  transferPaymentReducer: {
    transferPaymentResponse: any
    isLoading: boolean
  }
  errorReducer: { error: any; type: string }
}) => {
  const {
    stepLevel,
    paymentSetupStep,
    energyCreditsCheckBoxState,
    howToPay,
    communityFeeCheckBoxState,
  } = setStepReducer
  const {
    communityFee: communityFeeGlobal,
    energyCreditFee,
    processingFee,
    transactionStatus,
  } = paymentValuesReducer
  const { transferPaymentResponse, isLoading } = transferPaymentReducer

  return {
    stepLevel,
    howToPay,
    energyCreditsCheckBoxState,
    paymentSetupStep,
    communityFeeGlobal,
    energyCreditFee,
    processingFee,
    transactionStatus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation)
