import React from 'react'
import { Skeleton } from 'antd'
import './Loader.styles.scss'

const WhatToPayForLoader: React.FC = () => {
  return (
    <div
      style={{
        display: 'grid',
        gap: '3rem',
        paddingTop: '2%',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: '10%',
          width: '80%',
        }}
      >
        <Skeleton.Button active size="default" shape="square" style={{ width: '100%' }} />
        <Skeleton.Button active size="default" shape="square" style={{ width: '100%' }} />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: '10%',
          width: '80%',
        }}
      >
        <Skeleton.Button active size="default" shape="square" style={{ width: '100%' }} />
        <Skeleton.Button active size="default" shape="square" style={{ width: '100%' }} />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'none',
          gap: '40%',
        }}
      >
        <Skeleton.Button active size="default" shape="square" style={{ width: '40%' }} />
        <Skeleton.Button active size="default" shape="square" style={{ width: '40%' }} />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: '10%',
          width: '80%',
          paddingLeft: '10%',
          paddingTop: '40px',
        }}
      >
        <Skeleton.Button active size="default" shape="square" style={{ width: '95%' }} />
        <Skeleton.Button active size="default" shape="square" style={{ width: '100%' }} />
      </div>
    </div>
  )
}

export default WhatToPayForLoader
