export default function localStorageService() {
  const get = (key: string) => {
    const fromLocalStorage = localStorage.getItem(key) as string
    if (!['undefined', 'null'].includes(fromLocalStorage)) {
      return JSON.parse(fromLocalStorage)
    }
    return null
  }

  const store = (key: string, payload: any) => {
    localStorage.setItem(key, JSON.stringify(payload))
  }

  const clear = () => {
    localStorage.clear()
  }

  const removeItem = (key: string) => {
    localStorage.removeItem(key)
  }

  return { get, store, clear, removeItem }
}
