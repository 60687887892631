import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'

import './PaymentSetup.styles.scss'

import { PaymentSetupStep } from '../Dashboard/Dashboard.metadata'
import WhatToPayFor from './WhatToPayFor/WhatToPayFor'
import HowToPay from './HowToPay/HowToPay'
import PaymentReview from './PaymentReview/PaymentReview'

const { TabPane } = Tabs
interface IPaymentSetupProps {
  paymentSetupStep: number
}

const PaymentSetup = ({ paymentSetupStep }: IPaymentSetupProps): ReactElement => {
  return (
    <>
      <Tabs defaultActiveKey="0" activeKey={`${paymentSetupStep}`} animated>
        <TabPane tab="What to Pay For" key={PaymentSetupStep.WhatToPayFor}>
          <WhatToPayFor />
        </TabPane>
        <TabPane tab="How to Pay" key={PaymentSetupStep.HowToPay}>
          <HowToPay />
        </TabPane>
        <TabPane tab="Payment Review" key={PaymentSetupStep.PaymentReview}>
          <PaymentReview />
        </TabPane>
      </Tabs>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({})

const mapStateToProps = ({
  setStepReducer,
}: {
  setStepReducer: {
    paymentSetupStep: number
  }
}) => {
  const { paymentSetupStep } = setStepReducer

  return {
    paymentSetupStep,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSetup)
