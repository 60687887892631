import { Action, Dispatch } from 'redux'
import { IPaymentGateway } from '../../models/initialize-payment.model'
import { PAYMENT_VALUES_TYPE } from '../types'
import { SET_PAYMENT_GATEWAY } from '../types/payment-values.type'

const {
  SET_COMMUNITY_FEE,
  SET_ENERGY_CREDIT_FEE,
  SET_PROCESSING_FEE,
  SET_TRANSACTION_STATUS,
} = PAYMENT_VALUES_TYPE

export const setCommunityFee = (communityFee: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_COMMUNITY_FEE,
      payload: communityFee,
    })
  }
}

export const setEnergyCreditFee = (energyCreditFee: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_ENERGY_CREDIT_FEE,
      payload: energyCreditFee,
    })
  }
}

export const setProcessingFee = (processingFee: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_PROCESSING_FEE,
      payload: processingFee,
    })
  }
}

export const setTransactionStatus = (transactionStatus: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_TRANSACTION_STATUS,
      payload: transactionStatus,
    })
  }
}

export const setPaymentGateway = (paymentGateway: IPaymentGateway) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_PAYMENT_GATEWAY,
      payload: paymentGateway,
    })
  }
}
