import React, { useEffect, useState } from 'react'
import { Button, Radio, Typography } from 'antd'
import { DownloadOutlined, CheckOutlined } from '@ant-design/icons'
import './CheckBox.styles.scss'

interface ICheckBox {
  name: string
  state: boolean
  onClick: any
  style?: any
  disabled?: boolean
  testID?: string
}

const CheckBox = (props: ICheckBox) => {
  const { Text, Title } = Typography

  const { name, state, onClick, style, disabled, testID } = props
  const [check, setCheck] = useState(state)

  const handleOnClick = () => {
    const toggleCheck = !check
    setCheck(toggleCheck)
    onClick(toggleCheck)
  }
  return (
    <Button
      disabled={disabled}
      type="text"
      style={{ ...style }}
      className="check-container"
      onClick={handleOnClick}
    >
      <div className="check-container check-container-active">
        <div className={`check-box ${check ? 'check-box-active' : 'check-box-inactive'}`}>
          <CheckOutlined className="check-icon" />
        </div>
        <Text className="check-name">{name}</Text>
      </div>
    </Button>
  )
}

export default CheckBox
