import React, { ReactElement } from 'react'
import { Typography } from 'antd'
import './Footer.styles.scss'
import { useMediaQueries } from '../../hooks'
import ShyftLogo from '../../assets/logo.png'

const Footer = (): ReactElement => {
  const { Text, Link } = Typography
  const { isDashboardMobile } = useMediaQueries()

  return (
    <>
      {isDashboardMobile ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30%' }}>
          <Text
            className="mobile-t-and-c-text"
            style={{ display: 'flex', justifyContent: 'center', fontSize: '0.8rem' }}
          >
            Solutions&nbsp; delivered&nbsp; by
          </Text>
          <img src={ShyftLogo} alt="shyft-logo" className="footer-responsive-logo" />
        </div>
      ) : (
        <div className="bg-soltice-purple footer-div">
          <Text className="footer-text">
            By using this site, you agree to
            <br />
            the{' '}
            <Link href="https://shyftpower.com/terms-of-use" target="_blank" id="terms-of-use">
              {' '}
              Terms of Use{' '}
            </Link>{' '}
            and{' '}
            <Link href="https://shyftpower.com/privacy" target="_blank" id="privacy-policy">
              Privacy Policy
            </Link>
          </Text>
        </div>
      )}
    </>
  )
}

export default Footer
