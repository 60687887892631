import { AxiosInstance, CancelToken } from 'axios'
import { deserialize } from '../utils/json-api.util'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'
import { IVerifyPaymentOption } from '../models/verify-payment.model'

export const verifyPayment = (axiosInstance: AxiosInstance) => {
  return async (
    options: IVerifyPaymentOption,
    source: { token: CancelToken; cancel: () => void },
  ) => {
    const facilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)
    const { reference, paymentProcessor } = options

    const { data } = await axiosInstance.get(
      `residents/facilityUnits/${facilityUnitId}/payments/${reference}/status?paymentProcessor=${paymentProcessor}`,
      {
        cancelToken: source?.token,
      },
    )

    source?.cancel()
    const result = await deserialize(data)
    return result
  }
}
