import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'

import { Typography, Form, Radio, Space, Row, Col } from 'antd'

import {
  setHowToPay as setHowToPayFnc,
  setProcessingFee as setProcessingFeeFnc,
} from '../../../redux/actions'
import { HowToPayTypes } from '../../../utils/constant'

import './HowToPay.styles.scss'

interface IHowToPayProps {
  setHowToPay: any
  energyCreditsCheckBoxState: boolean
  howToPay: string
  setProcessingFee: (value: number) => void
}

const HowToPay = ({
  setHowToPay,
  energyCreditsCheckBoxState,
  howToPay,
  setProcessingFee,
}: IHowToPayProps): ReactElement => {
  const { Text, Title } = Typography

  const handleSelectHowToPay = (event: any) => {
    setProcessingFee(0)
    setHowToPay(event?.target?.value)
  }

  return (
    <>
      <Title
        level={4}
        className="header-text"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        How would you like to pay?
      </Title>
      <Title
        level={5}
        className="sub-heading-text"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        Select one
      </Title>

      <Form layout="vertical" className="form-div">
        <br />

        <Row gutter={1}>
          <Col xs={24} sm={24} md={{ span: 12 }} lg={12} xl={12}>
            <Radio.Group disabled={false} className="radio-group-box" value={howToPay}>
              <Space direction="vertical">
                <Radio
                  className={`how-to-radio-style-box ${
                    energyCreditsCheckBoxState && 'disabled-shade'
                  }`}
                  value={HowToPayTypes.EnergyCredit}
                  onChange={(event) => handleSelectHowToPay(event)}
                  disabled={energyCreditsCheckBoxState}
                >
                  <div>
                    <Text>Energy credit balance</Text>
                    <br />
                    <Text className="pay-method-desc">
                      Energy credits can only be used for transactions exclusively paying for fees.
                    </Text>
                  </div>
                </Radio>
                <Radio
                  className="how-to-radio-style-box"
                  value={HowToPayTypes.NewPayment}
                  onChange={(event) => handleSelectHowToPay(event)}
                >
                  <div>
                    <Text>New payment</Text>
                    <br />
                    <Text className="pay-method-desc">
                      Bank card, transfer, USSD. Other options may be available.
                    </Text>
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setHowToPay: (howToPay: string) => dispatch(setHowToPayFnc(howToPay)),
  setProcessingFee: (processingFee: number) => dispatch(setProcessingFeeFnc(processingFee)),
})

const mapStateToProps = ({
  setStepReducer,
}: {
  setStepReducer: {
    howToPay: string
    energyCreditsCheckBoxState: boolean
  }
}) => {
  const { howToPay, energyCreditsCheckBoxState } = setStepReducer

  return {
    howToPay,
    energyCreditsCheckBoxState,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HowToPay)
