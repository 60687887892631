import React, { ReactElement } from 'react'
import { Typography } from 'antd'
import './Header.styles.scss'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'

const Links = (): ReactElement => {
  const { Link, Title } = Typography
  return (
    <>
      <Link
        href="https://www.shyftpower.com/flexview-resident-faqs"
        onClick={() => trackAmplitudeEvent('clicked faqs')}
        target="_blank"
        id="faq-link"
        style={{ marginRight: '20%' }}
      >
        <Title level={3} className="faq-text">
          FAQ
        </Title>
      </Link>
    </>
  )
}

export default Links
