import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import FlexviewLogo from '../../assets/flexview-logo.png'
import { ReactComponent as ShyftLogo } from '../../assets/shyft-logo-white.svg'
import { useMediaQueries } from '../../hooks'

import StoreIcons from './StoreIcons'
import Links from './Links'
import Logout from './Logout'

import './Header.styles.scss'

const Header = (): ReactElement => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const { isDashboardMobile } = useMediaQueries()
  const location = useLocation()
  const { pathname } = location

  if (pathname === '/payment/callback' || pathname === '/auth/callback') {
    return <></>
  }

  const handleHamburgerOpen = () => {
    setMobileNavOpen((hamburgerOpen: boolean) => !hamburgerOpen)
  }

  const renderHamburgerMenu = (open: boolean) => {
    return (
      <>
        {open && (
          <div className="hamburger-menu-list">
            <span style={{ textAlign: 'center' }}>
              <Links />
            </span>
            <Logout />
          </div>
        )}
      </>
    )
  }

  const renderHamburgerIcon = () => {
    return (
      <div className="hamburger-menu" onClick={handleHamburgerOpen} role="presentation">
        <div className={`bar-one ${mobileNavOpen && 'bar-one-transform'}`} />
        <div className={`bar-two ${mobileNavOpen && 'bar-two-transform'}`} />
        <div className={`bar-three ${mobileNavOpen && 'bar-three-transform'}`} />
      </div>
    )
  }

  return (
    <div className={`${isDashboardMobile ? '' : 'header-div'}`}>
      <div className={`${isDashboardMobile ? 'mobile-logo' : 'left-div'}`}>
        {isDashboardMobile ? (
          <>
            {pathname === '/dashboard' && renderHamburgerIcon()}
            <div className="mobile-logo-image">
              <img src={FlexviewLogo} alt="logo" className="responsive" />
            </div>
          </>
        ) : (
          <ShyftLogo data-testid="shyft-logo" />
        )}
      </div>
      <div className="right-div">
        {pathname === '/'
          ? !isDashboardMobile && <StoreIcons />
          : !isDashboardMobile && (
              <>
                <Links />
                <Logout />
              </>
            )}
      </div>
      {isDashboardMobile && pathname === '/dashboard' && renderHamburgerMenu(mobileNavOpen)}
    </div>
  )
}

export default Header
