import React, { ReactElement, useEffect } from 'react'
import { Row, Col } from 'antd'
import SuccessComponent from './SuccessComponent'
import FailureComponent from './FailureComponent'
import './ConfirmationPage.styles.scss'
import useStsMeter from '../../hooks/useStsMeter'

interface IConfirmation {
  status: string
  setStepLevel: (number: number) => void
  cancel: () => void
  makeNewPayment: () => void
  onLogout: () => Promise<void>
}

const ConfirmationPage = ({
  status,
  setStepLevel,
  cancel,
  makeNewPayment,
  onLogout,
}: IConfirmation): ReactElement => {
  const isSTSMeter = useStsMeter()

  useEffect(() => setStepLevel(3))

  const getScreen = () => {
    if (status === 'completed') {
      return (
        <SuccessComponent cancel={() => makeNewPayment()} onLogout={onLogout} status={status} />
      )
    }

    if (isSTSMeter) {
      // TODO: include check for failed token generation
      return (
        <FailureComponent
          cancel={() => cancel()}
          onLogout={onLogout}
          status={status}
          tokenGenerationFailed
          textBody=""
          textButton="Start Over Using Flutterwave"
        />
      )
    }

    if (status === 'failed') {
      return (
        <FailureComponent
          tokenGenerationFailed={false}
          cancel={() => cancel()}
          onLogout={onLogout}
          status={status}
          textBody={
            <>
              {' '}
              An error occurred with your transaction. Please try again. <br /> Refer to
              <a
                target="blank"
                className="helpLink"
                href="https://shyftpowersupport.zendesk.com/hc/en-us/articles/22491371201949-How-can-Residents-avoid-Failed-Payments-v-2"
              >
                <u>{' Help/FAQ '}</u>
              </a>
              article for tips on payments. Contact your property&apos;s facility/power manager for
              further questions or comments.{' '}
            </>
          }
          textButton="Start Over Using Flutterwave"
        />
      )
    }
  }
  return (
    <Row className="confirmation-div">
      <Col>{getScreen()}</Col>
    </Row>
  )
}

export default ConfirmationPage
