import { AxiosInstance } from 'axios'
import { deserialize } from '../utils/json-api.util'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'

export const transferCredit = (axiosInstance: AxiosInstance) => {
  return async (payload: { amountCents: number }) => {
    const id = localStorageService().get(KeyStorage.activeFacilityUnitId)
    const { data } = await axiosInstance.post(
      `v1/residents/facilityUnits/${id}/transferCreditsToFees`,
      payload,
    )
    const result = await deserialize(data)
    return result
  }
}
