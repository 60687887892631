import React from 'react'
import { Typography, Button, Alert } from 'antd'
import { Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { KeyStorage } from '../../models'

import PhoneImage from '../../assets/phone.png'

import Footer from '../../components/Footer'
import CustomButton from '../../components/CustomButton'
import { localStorageService } from '../../services'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'
import { useNetwork, useMediaQueries, useAuth0 } from '../../hooks'

import './LandingPage.styles.scss'
import Header from '../../components/Header'

const LandingPage: React.FC = () => {
  const auth0 = useAuth0()
  const location = useLocation()
  const isOnline = useNetwork()
  const { isDashboardMobile } = useMediaQueries()

  const isAuthenticated = localStorageService().get(KeyStorage.isAuthenticated)

  const { Text, Link } = Typography

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/dashboard' }} />
  }

  const login = async () => {
    trackAmplitudeEvent('clicked login_button')
    await auth0.login()
  }

  const locationQuery = queryString.parse(location.search)

  return (
    <>
      <Header />
      <div className={`${isDashboardMobile ? '' : 'bg-soltice-purple'}`}>
        {!isOnline && (
          <Alert
            showIcon={false}
            type="error"
            message="No internet connection. Please ensure your connected to the internet to complete this transaction."
            banner
            className="alert-error-div"
          />
        )}
        {locationQuery?.unauthorized && (
          <Alert
            showIcon={false}
            type="error"
            message="The account you entered is not authorized for web payments. Please try another account."
            banner
            className="alert-error-div"
          />
        )}
        {locationQuery?.autherror && (
          <Alert
            showIcon={false}
            type="error"
            message="Your session has timed out, please login to continue."
            banner
            className="alert-error-div"
          />
        )}

        {isDashboardMobile ? (
          <div className="mobile-item-column">
            <Text className="mobile-welcome-text">Easily make payments to a FlexView Meter</Text>
            <div className="mobile-sign-in-button">
              <CustomButton
                bgColor="yellow"
                color="purple"
                text="Sign in"
                onClick={login}
                style={{ padding: '0 20px 0 20px' }}
              />
            </div>

            <div className="mobile-t-and-c-div">
              <Text className="mobile-t-and-c-text">
                By using this website, you agree to the
                <br />{' '}
                <Link
                  href="https://shyftpower.com/terms-of-use"
                  target="_blank"
                  id="terms-of-use"
                  style={{ textDecoration: 'underline' }}
                >
                  {' '}
                  Terms of Use <span>&amp;</span>{' '}
                </Link>
                <Link
                  href="https://shyftpower.com/privacy"
                  target="_blank"
                  id="privacy-policy"
                  style={{ textDecoration: 'underline' }}
                >
                  Privacy Policy
                </Link>
              </Text>
            </div>
          </div>
        ) : (
          <div className="bg-soltice-purple page">
            <div className="item-column phone-placeholder">
              <img
                className="responsive-image"
                src={PhoneImage}
                alt="phone"
                data-testid="phone-placeholder"
              />
            </div>
            <div className="item-column">
              <Text className="welcome-text">
                Easily & securely make payments to a FlexView Meter
              </Text>
              <div className="sign-in-button">
                <CustomButton bgColor="yellow" color="purple" text="Sign in" onClick={login} />
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  )
}

export default LandingPage
