import { IInitializePayment } from '../../models/initialize-payment.model'
import { INITIALIZE_PAYMENT_TYPE } from '../types'
import { INITIALIZE_PAYMENT_RESET } from '../types/initialize-payment.type'

type InitializeAction = {
  type: string
  payload: IInitializePayment
}

export type InitializeStateType = {
  initializePaymentDetails: Record<string, unknown>
}

export const INITIAL_STATE: InitializeStateType = {
  initializePaymentDetails: {},
}

const {
  INITIALIZE_PAYMENT_FAILURE,
  INITIALIZE_PAYMENT_REQUEST,
  INITIALIZE_PAYMENT_SUCCESS,
} = INITIALIZE_PAYMENT_TYPE

export default (state = INITIAL_STATE, action: InitializeAction) => {
  switch (action.type) {
    case INITIALIZE_PAYMENT_RESET:
      return { ...state, initializePaymentDetails: {}, isLoading: false }
    case INITIALIZE_PAYMENT_REQUEST:
      return { ...state, initializePaymentDetails: {}, isLoading: true }
    case INITIALIZE_PAYMENT_SUCCESS:
      return { ...state, initializePaymentDetails: action.payload, isLoading: false }
    case INITIALIZE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
