import React from 'react'

export interface EnvContextProps {
  AMPLITUDE_API_KEY: string
  FLEXVIEW_API_URL: string
  AUTH0_CLIENTID: string
  AUTH0_AUDIENCE: string
  AUTH0_DOMAIN: string
  SENTRY_DSN: string
  DEPLOYMENT_ENV: string
  AUTH0_REDIRECT_URL: string
  FLUTTERWAVE_CALLBACK_URL: string
}

export const EnvContext = React.createContext({
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
  FLEXVIEW_API_URL: process.env.REACT_APP_FLEXVIEW_API_URL as string,
  AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID as string,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN as string,
  DEPLOYMENT_ENV: process.env.REACT_APP_DEPLOYMENT_ENV as string,
  AUTH0_REDIRECT_URL: process.env.REACT_APP_AUTH0_REDIRECT_URL as string,
  FLUTTERWAVE_CALLBACK_URL: process.env.REACT_APP_FLUTTERWAVE_CALLBACK_URL as string,
})
