import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as Sentry from '@sentry/react'

import thunk from 'redux-thunk'

import combineReducer from '../reducers/combineReducer'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    if (Object.keys(state).length === 0) {
      return null
    }
  },
})

const composeEnhancers = composeWithDevTools || compose

export default () => {
  return createStore(combineReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer))
}
