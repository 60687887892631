import React, { ReactElement } from 'react'
import { Steps, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useMediaQueries } from '../../hooks'

interface IProgressBar {
  stepLevel: number
  description?: string
  showPaymentPortal?: boolean
  isMobileOrTablet: boolean
}

const ShowTooltip = ({ text }: { text: string }): ReactElement => (
  <Tooltip placement="bottom" title={text}>
    <InfoCircleOutlined style={{ opacity: 0.1 }} />
  </Tooltip>
)

const ProgressBar = ({
  stepLevel,
  description,
  showPaymentPortal,
  isMobileOrTablet,
}: IProgressBar): ReactElement => {
  const { Step } = Steps

  const { isProgressBarMobile } = useMediaQueries()

  return (
    <>
      <div className="progress-div-steps">
        <Steps
          progressDot
          current={stepLevel}
          direction={isMobileOrTablet ? 'horizontal' : 'vertical'}
        >
          <Step
            title={isProgressBarMobile ? <ShowTooltip text="Payee Details" /> : 'Payee Details'}
            description=""
            className="step-item"
          />
          <Step
            title={isProgressBarMobile ? <ShowTooltip text="Payment Set-Up" /> : 'Payment Set-Up'}
            description=""
            className="step-item"
          />
          <Step
            title={
              isProgressBarMobile ? <ShowTooltip text="Payment Processing" /> : 'Payment Processing'
            }
            description={showPaymentPortal && description}
            className="step-item"
          />
          <Step
            title={isProgressBarMobile ? <ShowTooltip text="Confirmation" /> : 'Confirmation'}
            description=""
            className="step-item"
          />
        </Steps>
      </div>
    </>
  )
}

export default ProgressBar
