import { localStorageService } from '../services'

const useStsMeter = () => {
  const activeMeterType = localStorageService().get('activeFacilityUnitUserRole')

  const isSTSMeter = activeMeterType.facilityUnit.meter.platformType === 'stsNonSmart'

  return isSTSMeter
}

export default useStsMeter
