import { useMediaQuery } from 'react-responsive'

const useMediaQueries = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 900px)',
  })
  const isMobileOrTablet = isTabletOrMobile || isTabletOrMobileDevice
  const isIframeMobile = useMediaQuery({ query: '(max-width: 4780px)' })
  const isMobileDashboardScreen = useMediaQuery({ query: '(max-width: 765px)' })
  const isMobileDashboardDevice = useMediaQuery({
    query: '(max-device-width: 765px)',
  })

  const isMobileProgressBarScreen = useMediaQuery({ query: '(max-width: 590px)' })
  const isMobileProgressBarDevice = useMediaQuery({
    query: '(max-device-width: 590px)',
  })
  const isProgressBarMobile = isMobileProgressBarScreen || isMobileProgressBarDevice

  const isDashboardMobile = isMobileDashboardScreen || isMobileDashboardDevice

  return {
    isTabletOrMobile,
    isTabletOrMobileDevice,
    isMobileOrTablet,
    isIframeMobile,
    isDashboardMobile,
    isProgressBarMobile,
  }
}

export default useMediaQueries
