import { CALCULATE_LINE_ITEM_TYPE } from '../types'
import { ILineItem } from '../../models/line-item.model'

type LineItemAction = {
  type: string
  payload: ILineItem
}

export type ILineItemStateType = {
  lineItems: ILineItem
  isLoading: boolean
}

export const INITIAL_STATE: ILineItemStateType = {
  lineItems: {},
  isLoading: false,
}

const {
  CALCULATE_LINE_ITEM_FAILURE,
  CALCULATE_LINE_ITEM_SUCCESS,
  CALCULATE_LINE_ITEM_REQUEST,
} = CALCULATE_LINE_ITEM_TYPE

export default (state = INITIAL_STATE, action: LineItemAction) => {
  switch (action.type) {
    case CALCULATE_LINE_ITEM_REQUEST:
      return { ...state, lineItems: {}, isLoading: true }
    case CALCULATE_LINE_ITEM_SUCCESS:
      return { ...state, lineItems: action.payload, isLoading: false }
    case CALCULATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
