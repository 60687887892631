/* eslint-disable */
import axios from 'axios'
import * as rax from 'retry-axios'
import { KeyStorage } from '../models'
import useAuth0 from './useAuth0'
import localStorageService from '../services/local-storage.service'
import { EnvContext } from '../utils/context'
import { useContext } from 'react'

export default function useAxios(passtoken?: string) {
  const auth0 = useAuth0()
  const env = useContext(EnvContext)

  const token = localStorageService().get(KeyStorage.token)

  const accessToken = passtoken || token

  const axiosInstance = axios.create({
    baseURL: env.FLEXVIEW_API_URL,
  })

  axiosInstance.defaults.raxConfig = {
    instance: axiosInstance,
    retry: 3,
    noResponseRetries: 3,
    onRetryAttempt: (err: any) => {
      const cfg = rax.getConfig(err) as { currentRetryAttempt: number }
      console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
    },
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorageService().get(KeyStorage.token)

      const accessToken = passtoken || token
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  const handleLogout = (auth0: any) => {
    auth0.logout({ unauthorized: true })
    window.history.replaceState({ unauthorized: true }, document.title, window.location.pathname)
    localStorageService().clear()
  }

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config
      if (originalConfig?.url !== '/' && error.response) {
        // handle refresh access token
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const newAccessToken = await auth0.getAccessToken()
            localStorageService().store(KeyStorage.token, newAccessToken)
            return axiosInstance(originalConfig)
          } catch (error) {
            // Logout user if refresh token fails
            handleLogout(auth0)
            throw error
          }
        }
        // handles user initial login with no access
        else if ([401, 403].includes(error.response.status)) {
          handleLogout(auth0)
        }
      }
      throw error
    },
  )

  rax.attach(axiosInstance)

  return axiosInstance
}
