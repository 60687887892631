// export const formatMoney = (num: string | number): string | number =>
//   num
//     ? Number(num)
//         .toFixed(2)
//         .replace(/\d(?=(\d{3})+\.)/g, '$&,')
//     : '0.00'

export const formatMoney = (num: string | number) =>
  Number(num)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const formatCurrency = (amount: number | undefined) => {
  amount = amount ? amount : 0
  if (amount < 0) {
    return `-₦${formatMoney(Math.abs(amount) / 100)}`
  }

  return `₦${formatMoney(amount / 100)}`
}
