import React, { ReactElement } from 'react'
import { Row, Col, Typography } from 'antd'

import Lottie from 'react-lottie'

import animationData from '../../assets/breath-loader-2.json'
import './VerificationLoader.styles.scss'

interface IVerificationLoaderProps {
  isMobileOrTablet?: boolean
}

const VerificationLoader: React.FC<IVerificationLoaderProps> = ({
  isMobileOrTablet,
}: IVerificationLoaderProps): ReactElement => {
  const { Text } = Typography

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Row style={{ marginLeft: '10%' }}>
      <Col span={22}>
        {isMobileOrTablet ? (
          <div>
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
        ) : (
          <Lottie options={defaultOptions} height={450} width={450} />
        )}

        <Text className="page-text">Verifying your transaction . . .</Text>
      </Col>
    </Row>
  )
}

export default VerificationLoader
