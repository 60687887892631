import { combineReducers } from 'redux'
import getUserReducer from './get-user.reducer'
import setStepReducer from './set-step.reducer'
import paymentValuesReducer from './payment-values.reducer'
import errorReducer from './error.reducer'
import calculateLineItemReducer from './calculate-line-item.reducer'
import getFacilityUnitReducer from './get-facility-unit.reducer'
import transferPaymentReducer from './transfer-payment.reducer'
import initializePaymentReducer from './initialize-payment.reducer'
import verifyPaymentReducer from './verify-payment.reducer'
import globalPaymentSetupReducer from './global-states.reducer'

// import other reducers here and pass to combineReducer
export default combineReducers({
  getUserReducer,
  setStepReducer,
  transferPaymentReducer,
  paymentValuesReducer,
  calculateLineItemReducer,
  errorReducer,
  getFacilityUnitReducer,
  initializePaymentReducer,
  verifyPaymentReducer,
  globalPaymentSetupReducer,
})
