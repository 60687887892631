import amplitude from 'amplitude-js'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'

import { IResidentUserRole } from '../models/facility-unit.model'
import { formatFacilityUnitName } from './format-facilityunit-name.util'

const localStorage = localStorageService()

export const trackAmplitudeEventProperty = async (eventName: string) => {
  const user: any = localStorage.get(KeyStorage.user)
  const facilityUnitId = localStorage.get(KeyStorage.activeFacilityUnitId)

  let facilityUnitProperties: { [key: string]: any } = {}

  if (user && facilityUnitId) {
    const userDetails = user
    const currentFacilityUnitResidentRole = userDetails?.residentUserRoles?.find(
      (item: IResidentUserRole) => item?.facilityUnit.id === facilityUnitId,
    )

    facilityUnitProperties = {
      facility_unit_id: facilityUnitId,
    }

    if (currentFacilityUnitResidentRole?.facilityUnit) {
      const facilityUnitName = currentFacilityUnitResidentRole?.facilityUnit
        ? formatFacilityUnitName(currentFacilityUnitResidentRole)
        : undefined

      facilityUnitProperties.facility_unit_name = facilityUnitName
    }
  }

  amplitude.getInstance().logEvent(eventName, facilityUnitProperties)
}

export const trackAmplitudeEvent = async (eventName: string) => {
  amplitude.getInstance().logEvent(eventName)
}
