import {
  CALCULATE_LINE_ITEM_TYPE,
  GET_FACILITY_UNIT_TYPE,
  GET_USER_TYPE,
  INITIALIZE_PAYMENT_TYPE,
  VERIFY_PAYMENT_TYPE,
} from '../types'

type ErrorAction = {
  type: string
  payload: Error | null
}

export type IErrorStateType = {
  error: null | undefined | Error
  type: string
}

const { GET_USER_FAILURE } = GET_USER_TYPE
const { CALCULATE_LINE_ITEM_FAILURE } = CALCULATE_LINE_ITEM_TYPE
const { GET_FACILITY_UNIT_FAILURE } = GET_FACILITY_UNIT_TYPE
const { INITIALIZE_PAYMENT_FAILURE } = INITIALIZE_PAYMENT_TYPE
const { VERIFY_PAYMENT_FAILURE } = VERIFY_PAYMENT_TYPE

export const INITIAL_STATE: IErrorStateType = {
  error: null,
  type: '',
}

export default (state = INITIAL_STATE, action: ErrorAction) => {
  switch (action.type) {
    case GET_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      }
    case GET_FACILITY_UNIT_FAILURE:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      }
    case CALCULATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      }
    case INITIALIZE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      }
    case VERIFY_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      }
    default:
      return state
  }
}
