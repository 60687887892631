import React from 'react'
import { Button, Typography } from 'antd'
import CustomButton from '../CustomButton'
import './CancelTransaction.scss'

interface ICancelTransaction {
  cancel: (() => void) | null
  goBack: () => null | void
  showGoBack?: boolean
  showPaymentPortal?: boolean
  handleInitializePayment: () => null | void
  disableProceedPayment: boolean
  toContinue?: boolean
  isBtnLoading?: boolean
}

const CancelTransaction = ({
  cancel,
  goBack,
  showGoBack = false,
  toContinue,
  showPaymentPortal,
  handleInitializePayment,
  disableProceedPayment,
  isBtnLoading,
}: ICancelTransaction) => {
  const { Text, Link } = Typography
  return (
    <div className="progress-div-bottom">
      {!showPaymentPortal && toContinue ? (
        <CustomButton
          bgColor="yellow"
          color="white"
          shadow
          text="Continue"
          onClick={handleInitializePayment}
          disabled={disableProceedPayment}
          data-testid="continue"
          id="continue"
          isLoading={isBtnLoading}
        />
      ) : null}
      <div className="progress-div-bottom-text">
        <Text>
          By using this website and making payments, you agree to the{' '}
          <Link
            href="https://shyftpower.com/terms-of-use"
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            Terms of Use.
          </Link>
        </Text>
      </div>
      <div
        className="extra-action-btns"
        style={{ justifyContent: !showGoBack ? 'center' : 'space-between' }}
      >
        {showGoBack ? (
          <Button type="link" onClick={goBack} id="back-button">
            {' '}
            Back{' '}
          </Button>
        ) : null}
        {cancel ? (
          <Button type="link" onClick={cancel} id="cancel-button">
            {' '}
            Cancel{' '}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default CancelTransaction
