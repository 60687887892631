import { SET_STEP_TYPE } from '../types'

type SetStepActionAction = {
  type: string
  payload: any
}

export type ISetStepStateType = {
  stepLevel: number
  paymentSetupStep: number
  howToPay: string
  energyCreditsCheckBoxState: boolean
  communityFeeCheckBoxState: boolean
}

export const INITIAL_STATE: ISetStepStateType = {
  stepLevel: 0,
  paymentSetupStep: 0,
  howToPay: '',
  energyCreditsCheckBoxState: false,
  communityFeeCheckBoxState: false,
}

const {
  SET_STEP_LEVEL,
  SET_PAYMENT_SETUP_STEP,
  SET_HOW_TO_PAY,
  ENERGY_CREDITS_CHECK_BOX,
  COMMUNITY_FEE_CHECK_BOX,
} = SET_STEP_TYPE

export default (state = INITIAL_STATE, action: SetStepActionAction) => {
  switch (action.type) {
    case SET_STEP_LEVEL:
      return { ...state, stepLevel: action.payload }
    case SET_PAYMENT_SETUP_STEP:
      return { ...state, paymentSetupStep: action.payload }
    case SET_HOW_TO_PAY:
      return { ...state, howToPay: action.payload }
    case ENERGY_CREDITS_CHECK_BOX:
      return { ...state, energyCreditsCheckBoxState: action.payload }
    case COMMUNITY_FEE_CHECK_BOX:
      return { ...state, communityFeeCheckBoxState: action.payload }
    default:
      return state
  }
}
