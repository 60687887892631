import React from 'react'
import { Col, Row } from 'antd'
import './EnergyCreditSummary.styles.scss'
import { formatCurrency } from '../../utils/formatMoney'
import useStsMeter from '../../hooks/useStsMeter'

type Props = {
  currentRate: number
  energyCreditFee: number
  bold: boolean
}

const EnergyCreditSummary = ({ currentRate, energyCreditFee, bold }: Props) => {
  const isSTSMeter = useStsMeter()

  const creditPurchased = energyCreditFee ? energyCreditFee / (currentRate / 100) : 0

  return isSTSMeter ? (
    <div style={{ paddingLeft: '2.5rem' }}>
      <Row style={{ marginBottom: '10px' }}>
        <Col className={`font-primary ${bold && 'font-bold'}`} xs={10}>
          Current Rate
        </Col>
        <Col className="font-grey">{formatCurrency(currentRate)}/kWh</Col>
      </Row>

      <Row>
        <Col className={`font-primary ${bold && 'font-bold'}`} xs={10}>
          Credits Purchased With Input Amount
        </Col>
        <Col className="font-grey">{Number(creditPurchased).toFixed(2)} kWh</Col>
      </Row>
    </div>
  ) : null
}

export default EnergyCreditSummary
