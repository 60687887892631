import { AxiosInstance } from 'axios'
import { deserialize } from '../utils/json-api.util'

export const getUser = (axiosInstance: AxiosInstance) => {
  return async () => {
    const { data } = await axiosInstance.get('residents/me')
    const result = await deserialize(data)
    return result
  }
}
