import { Action, Dispatch } from 'redux'
import { RESET_PAYMENT_SETUP } from '../types/global-states.type'

export const resetPaymentSetupAction = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: RESET_PAYMENT_SETUP,
    })
  }
}
