import React from 'react'
import { Spin } from 'antd'
import './Loader.styles.scss'

const Loader: React.FC = () => {
  return (
    <div className="center">
      <Spin />
    </div>
  )
}

export default Loader
