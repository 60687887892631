import React, { ReactElement } from 'react'
import { Typography } from 'antd'
import { ReactComponent as AppStoreIcon } from '../../assets/appstore-icon.svg'
import { ReactComponent as PlayStoreIcon } from '../../assets/playstore-icon.svg'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'

const StoreIcons = (): ReactElement => {
  const { Link } = Typography
  return (
    <>
      <Link
        href="https://apps.apple.com/app/apple-store/id1535073309?mt=8"
        target="_blank"
        id="appstore-icon"
        style={{ marginRight: '3%' }}
        onClick={() => trackAmplitudeEvent('clicked app_store_link')}
      >
        <AppStoreIcon data-testid="appstore-icon" />
      </Link>
      <Link
        href="http://play.google.com/store/apps/details?id=com.submeteringresident"
        target="_blank"
        id="playstore-icon"
        onClick={() => trackAmplitudeEvent('clicked play_store_link')}
      >
        <PlayStoreIcon data-testid="playstore-icon" />
      </Link>
    </>
  )
}

export default StoreIcons
