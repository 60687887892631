import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUserAction } from '../../redux/actions'
import { IUser } from '../../models/user.model'
import { GET_USER_TYPE } from '../../redux/types'
import Loader from '../../components/Loader'
import { trackAmplitudeEvent } from '../../utils/amplitude-event.util'
import { useAxios, useAuth0 } from '../../hooks'

interface IAuthCallbackProps {
  userDataLoading: boolean
}

const { GET_USER_SUCCESS, GET_USER_FAILURE } = GET_USER_TYPE

const AuthCallback: React.FC<IAuthCallbackProps> = ({ userDataLoading }: IAuthCallbackProps) => {
  const { Text } = Typography
  const history = useHistory()

  const auth0 = useAuth0()
  const axiosInstance = useAxios()
  const dispatch = useDispatch()

  const getUserInfo = () => getUserAction(axiosInstance)(dispatch)

  useEffect(() => {
    const handleUserInfo = () => {
      getUserInfo().then(async (response: any) => {
        if (response?.type === GET_USER_SUCCESS) {
          trackAmplitudeEvent('navigated to dashboard')
          history.push('/dashboard')
        } else if (response?.type === GET_USER_FAILURE) {
          trackAmplitudeEvent('navigated to landing - unauthorized user')
          auth0.logout({ unauthorized: true })
        }
      })
    }
    handleUserInfo()
  }, [])

  return userDataLoading ? <Loader data-testid="auth-loader" /> : null
}

const mapStateToProps = ({
  getUserReducer,
}: {
  getUserReducer: { userDetails: IUser; isLoading: boolean }
}) => {
  const { userDetails, isLoading: userDataLoading } = getUserReducer

  return {
    userDetails,
    userDataLoading,
  }
}

export default connect(mapStateToProps)(AuthCallback)
