import { AxiosInstance } from 'axios'
import { deserialize } from '../utils/json-api.util'
import { KeyStorage } from '../models'
import { localStorageService } from '../services'
import { IInitializePaymentOption } from '../models/initialize-payment.model'

export const initializePayment = (axiosInstance: AxiosInstance) => {
  return async (options: IInitializePaymentOption) => {
    const facilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)

    const { data } = await axiosInstance.post(
      `residents/facilityUnits/${facilityUnitId}/payments/initialize`,
      options,
    )

    const result = await deserialize(data)
    return result
  }
}
