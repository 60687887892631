import { AxiosInstance } from 'axios'
import { deserialize } from '../utils/json-api.util'

import { KeyStorage } from '../models'
import { localStorageService } from '../services'

export const getFacilityUnit = (axiosInstance: AxiosInstance) => {
  return async () => {
    const facilityUnitId = localStorageService().get(KeyStorage.activeFacilityUnitId)

    const { data } = await axiosInstance.get(`v2/residents/facilityUnits/${facilityUnitId}`)
    const result = await deserialize(data)
    return result
  }
}
