export enum PaymentStepLevel {
  PayeeDetails = 0,
  PaymentSetup,
  PaymentProcessing,
  Confirmation,
}

export enum PaymentSetupStep {
  WhatToPayFor = 0,
  HowToPay,
  PaymentReview,
}
