import { Action, Dispatch } from 'redux'
import { SET_STEP_TYPE } from '../types'

const {
  SET_STEP_LEVEL,
  SET_PAYMENT_SETUP_STEP,
  SET_HOW_TO_PAY,
  ENERGY_CREDITS_CHECK_BOX,
  COMMUNITY_FEE_CHECK_BOX,
} = SET_STEP_TYPE

export const setStepLevel = (stepLevel: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_STEP_LEVEL,
      payload: stepLevel,
    })
  }
}

export const setPaymentSetupStep = (paymentSetupStep: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_PAYMENT_SETUP_STEP,
      payload: paymentSetupStep,
    })
  }
}

export const setHowToPay = (howToPay: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_HOW_TO_PAY,
      payload: howToPay,
    })
  }
}

export const setEnergyCreditsCheckBox = (energyCreditsCheckBoxState: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ENERGY_CREDITS_CHECK_BOX,
      payload: energyCreditsCheckBoxState,
    })
  }
}

export const setCommunityFeeCheckBox = (communityFeeCheckBoxState: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: COMMUNITY_FEE_CHECK_BOX,
      payload: communityFeeCheckBoxState,
    })
  }
}
