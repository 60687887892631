import { IVerifyPayment } from '../../models/verify-payment.model'
import { VERIFY_PAYMENT_TYPE } from '../types'
import { VERIFY_PAYMENT_RESET } from '../types/verify-payment.type'

type VerifyPaymentAction = {
  type: string
  payload: IVerifyPayment
}

export type VerifyPaymentStateType = {
  verifyPaymentDetails: Record<string, unknown>
}

export const INITIAL_STATE: VerifyPaymentStateType = {
  verifyPaymentDetails: {},
}

const {
  VERIFY_PAYMENT_FAILURE,
  VERIFY_PAYMENT_REQUEST,
  VERIFY_PAYMENT_SUCCESS,
} = VERIFY_PAYMENT_TYPE

export default (state = INITIAL_STATE, action: VerifyPaymentAction) => {
  switch (action.type) {
    case VERIFY_PAYMENT_RESET:
      return { ...state, verifyPaymentDetails: {}, isLoading: false }
    case VERIFY_PAYMENT_REQUEST:
      return { ...state, verifyPaymentDetails: {}, isLoading: true }
    case VERIFY_PAYMENT_SUCCESS:
      return { ...state, verifyPaymentDetails: action.payload, isLoading: false }
    case VERIFY_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
