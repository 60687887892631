/* eslint-disable */

import React, { Component, ErrorInfo, ReactNode } from 'react'
import { connect } from 'react-redux'
import useErrorLogging from '../hooks/useErrorLogging'

interface Props {
  children: ReactNode
  errorFromReducer: Error
  errorLogging: ReturnType<typeof useErrorLogging>
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

const withHook = (Component: any, useHook: any, hookName = 'hookvalue') => {
  return function WrappedComponent(props: any) {
    const hookValue = useHook()
    return <Component {...props} {...{ [hookName]: hookValue }} />
  }
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  // handle component rendering errors
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { logError } = this.props.errorLogging

    const { error } = this.state
    const { children, errorFromReducer } = this.props

    const sentryError = errorFromReducer || error

    sentryError && logError(sentryError)
    return children
  }
}

const mapStateToProps = ({ errorReducer }: { errorReducer: any }) => {
  const { error: errorFromReducer } = errorReducer
  return {
    errorFromReducer,
  }
}

export default withHook(connect(mapStateToProps)(ErrorBoundary), useErrorLogging, 'errorLogging')
