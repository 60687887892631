import { AxiosInstance, CancelToken } from 'axios'
import { Action, Dispatch } from 'redux'
import { verifyPayment } from '../../api/verify-payment.api'
import { IVerifyPayment, IVerifyPaymentOption } from '../../models/verify-payment.model'
import { VERIFY_PAYMENT_TYPE } from '../types'
import { VERIFY_PAYMENT_RESET } from '../types/verify-payment.type'

const {
  VERIFY_PAYMENT_FAILURE,
  VERIFY_PAYMENT_REQUEST,
  VERIFY_PAYMENT_SUCCESS,
} = VERIFY_PAYMENT_TYPE

const verifyPaymentPayload = (verifyPaymentData: IVerifyPayment) => ({
  type: VERIFY_PAYMENT_SUCCESS,
  payload: verifyPaymentData,
})

export const verifyPaymentAction = (
  axiosInstance: AxiosInstance,
  options: IVerifyPaymentOption,
  source: { token: CancelToken; cancel: () => void },
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: VERIFY_PAYMENT_REQUEST })
    return verifyPayment(axiosInstance)(options, source)
      .then((response) => {
        const verifyPaymentData = response
        return dispatch(verifyPaymentPayload(verifyPaymentData))
      })
      .catch((error) => {
        dispatch({
          type: VERIFY_PAYMENT_FAILURE,
          payload: error,
        })
      })
  }
}

export const resetVerifyPaymenPayLoad = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: VERIFY_PAYMENT_RESET,
      payload: {},
    })
  }
}
