import { Action, Dispatch } from 'redux'
import { AxiosInstance } from 'axios'
import { TRANSFER_PAYMENT_TYPE } from '../types'
import { transferCredit } from '../../api/tranfer-credit.api'

const {
  TRANSFER_PAYMENT_SUCCESS,
  TRANSFER_PAYMENT_REQUEST,
  TRANSFER_PAYMENT_FAILURE,
} = TRANSFER_PAYMENT_TYPE

export const makeTransferPayment = (
  axiosInstance: AxiosInstance,
  paload: { amountCents: number },
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: TRANSFER_PAYMENT_REQUEST })

    return transferCredit(axiosInstance)(paload)
      .then((response) => {
        return dispatch({ type: TRANSFER_PAYMENT_SUCCESS, payload: response })
      })
      .catch((error) => {
        dispatch({
          type: TRANSFER_PAYMENT_FAILURE,
          payload: error,
        })
      })
  }
}
