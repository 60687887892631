import React, { ReactElement, ReactNode, useEffect } from 'react'
import { Typography, Button } from 'antd'
import { ReactComponent as FailureImage } from '../../assets/failure.svg'
import { ReactComponent as NoToken } from '../../assets/notoken.svg'
import CustomButton from '../../components/CustomButton'

import './ConfirmationPage.styles.scss'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'

interface IConfirmationComponent {
  cancel: () => void
  onLogout: () => Promise<void>
  textBody: string | ReactNode
  textButton: string
  status?: string
  tokenGenerationFailed: boolean
}
const FailureComponent = ({
  cancel,
  onLogout,
  textBody,
  textButton,
  status,
  tokenGenerationFailed,
}: IConfirmationComponent): ReactElement => {
  const { Text } = Typography

  useEffect(() => {
    if (typeof textBody === 'string' && textBody.includes('This transaction timed out')) {
      trackAmplitudeEventProperty('viewed checkout_timed_out')
    } else {
      trackAmplitudeEventProperty('viewed payment_verification')
      trackAmplitudeEventProperty('viewed payment_failure')
    }
  }, [status])

  const handleMakeAnotherPayment = () => {
    return cancel()
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {tokenGenerationFailed ? (
        <>
          <NoToken data-testid="failure-image" style={{ marginLeft: '3%' }} />
          <Text className="confirmation-text bold-text">
            There was an issue generating your token.{' '}
          </Text>
          <Text className="header-text-failure">
            Your payment was confirmed but the token failed to generate. SHYFT has automatically
            been notified however you can contact SHYFT{' '}
            <a href="mailto:support@shyftpower.com">support@shyftpower.com</a> with your
            Confirmation details to generate a new token.
          </Text>
        </>
      ) : (
        <>
          <FailureImage data-testid="failure-image" style={{ marginLeft: '3%' }} />
          <Text className="confirmation-text">Oh no!</Text>
          <Text className="header-text-failure">{textBody}</Text>
        </>
      )}
      <div>
        <CustomButton
          bgColor="purple"
          color="white"
          cssClass="anotherPaymemtbutton"
          text={textButton || 'Try another payment'}
          onClick={handleMakeAnotherPayment}
        />
      </div>
      <Button type="link" onClick={onLogout} className="logout-button">
        <u> Logout </u>
      </Button>
    </div>
  )
}

export default FailureComponent
