import { RESET_PAYMENT_SETUP } from '../types/global-states.type'

export default (state = {}, action: any) => {
  switch (action.type) {
    case RESET_PAYMENT_SETUP:
      return {
        ...state,
        communityFee: 0,
        energyCreditFee: 0,
        processingFee: 0,
        transactionStatus: null,
        stepLevel: 0,
        paymentSetupStep: 0,
        howToPay: '',
        energyCreditsCheckBoxState: false,
        communityFeeCheckBoxState: false,
      }
    default:
      return state
  }
}
