import React from 'react'
import ReactDOM from 'react-dom'
import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { EnvContext, EnvContextProps } from './utils/context'

function initApp(config: EnvContextProps) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.DEPLOYMENT_ENV || 'development',
  })
  amplitude.getInstance().init(config.AMPLITUDE_API_KEY)

  ReactDOM.render(
    <React.StrictMode>
      <EnvContext.Provider value={config}>
        <App />
      </EnvContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

fetch('/config.json')
  .then((res) => res.json())
  .then((data) => {
    const config = {
      AMPLITUDE_API_KEY: data.REACT_APP_AMPLITUDE_API_KEY as string,
      FLEXVIEW_API_URL: data.REACT_APP_FLEXVIEW_API_URL as string,
      AUTH0_CLIENTID: data.REACT_APP_AUTH0_CLIENTID as string,
      AUTH0_AUDIENCE: data.REACT_APP_AUTH0_AUDIENCE as string,
      AUTH0_DOMAIN: data.REACT_APP_AUTH0_DOMAIN as string,
      SENTRY_DSN: data.REACT_APP_SENTRY_DSN as string,
      DEPLOYMENT_ENV: data.REACT_APP_DEPLOYMENT_ENV as string,
      AUTH0_REDIRECT_URL: data.REACT_APP_AUTH0_REDIRECT_URL as string,
      FLUTTERWAVE_CALLBACK_URL: data.REACT_APP_FLUTTERWAVE_CALLBACK_URL as string,
    }

    initApp(config)
  })
  .catch(() => {
    const config = {
      AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
      FLEXVIEW_API_URL: process.env.REACT_APP_FLEXVIEW_API_URL as string,
      AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID as string,
      AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
      SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN as string,
      DEPLOYMENT_ENV: process.env.REACT_APP_DEPLOYMENT_ENV as string,
      AUTH0_REDIRECT_URL: process.env.REACT_APP_AUTH0_REDIRECT_URL as string,
      FLUTTERWAVE_CALLBACK_URL: process.env.REACT_APP_FLUTTERWAVE_CALLBACK_URL as string,
    }

    initApp(config)
  })
