import React, { useState, ReactElement } from 'react'
import { Modal, Button } from 'antd'

interface ICustomModal {
  showModal: boolean
  onOk: () => void
  onCancel: () => void
  style?: Record<string, unknown>
  children: any
}

const CustomModal = ({
  showModal,
  onOk,
  onCancel,
  style,
  children,
}: ICustomModal): ReactElement => {
  const button = {
    background: '#817C92',
    borderRadius: '12px',
    border: 'none',
    float: 'right',
    width: '25%',
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  }
  return (
    <>
      <Modal
        title={
          <Button
            type="primary"
            onClick={onOk}
            style={{
              background: '#817C92',
              borderRadius: '12px',
              border: 'none',
              float: 'right',
              width: '25%',
            }}
          >
            Exit
          </Button>
        }
        visible={showModal}
        onOk={onOk}
        onCancel={onCancel}
        style={style}
      >
        {children}
      </Modal>
    </>
  )
}

export default CustomModal
