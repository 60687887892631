import React, { ReactElement } from 'react'
import { Col, Row, Typography } from 'antd'
import CustomButton from '../../components/CustomButton'
import './CrashPage.styles.scss'
import { trackAmplitudeEventProperty } from '../../utils/amplitude-event.util'

interface ICrashPageProps {
  status: number | string | null
}

const CrashPage: React.FC<ICrashPageProps> = ({ status }: ICrashPageProps): ReactElement => {
  const { Text } = Typography
  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '80vh',
        width: '100%',
      }}
    >
      <Col
        style={{
          alignContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'space-between',
          height: '40vh',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Text className="crash-header-text">Oh no!</Text>
          <Text className="crash-text" data-testid="crash-message">
            We’ve run into an error on our end but we’re working on it!
            <br />
            <span data-testid="error-code-text">{status && `code ${status}`}</span>
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }} className="button">
          <CustomButton
            bgColor="purple"
            color="white"
            text="Start a new transaction"
            onClick={() => {
              trackAmplitudeEventProperty('clicked start_new_transaction_button')
              window.location.reload()
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default CrashPage
