import React, { ReactElement } from 'react'

import { Provider } from 'react-redux'

import ErrorBoundary from './hocs/ErrorBoundary'
import configureStore from './redux/store'

import Routes from './routes'
import './App.scss'

const store = configureStore()

function App(): ReactElement {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </Provider>
  )
}

export default App
