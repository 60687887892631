import React, { useEffect } from 'react'
import queryString from 'query-string'
import { IInitializePayment } from '../../models/initialize-payment.model'
import { PaymentGatewayVerifyStatus } from '../../models/verify-payment.model'

interface IpaymentPortalIFrameProps {
  initializePaymentDetails: IInitializePayment
  setPaymentStatus: any
  handlePaymentGatewayCancelAction: () => void
}

const PaymentPortalIFrame: React.FC<IpaymentPortalIFrameProps> = ({
  initializePaymentDetails,
  setPaymentStatus,
  handlePaymentGatewayCancelAction,
}: IpaymentPortalIFrameProps) => {
  const onIframeNavigation = () => {
    const iframe = document.getElementById('paymentPortal') as HTMLIFrameElement
    try {
      const url = iframe?.contentWindow?.location?.href

      if (url?.includes('/payment/callback')) {
        const { query: paymentGatewayQueryParams } = queryString.parseUrl(url)
        if (
          [
            PaymentGatewayVerifyStatus.failed,
            PaymentGatewayVerifyStatus.success,
            PaymentGatewayVerifyStatus.successful,
            PaymentGatewayVerifyStatus.pending,
          ].includes(paymentGatewayQueryParams?.status as PaymentGatewayVerifyStatus)
        ) {
          if (
            paymentGatewayQueryParams?.status === PaymentGatewayVerifyStatus.success ||
            paymentGatewayQueryParams?.status === PaymentGatewayVerifyStatus.successful
          ) {
            setPaymentStatus(PaymentGatewayVerifyStatus.success)
          } else {
            setPaymentStatus(paymentGatewayQueryParams?.status as PaymentGatewayVerifyStatus)
          }
        } else if (paymentGatewayQueryParams?.status === 'cancelled') {
          handlePaymentGatewayCancelAction()
        }
      }
    } catch (err: any) {
      console.log(err.message, 'err')
    }
  }

  return (
    <iframe
      className="iframe-loader"
      seamless
      width="100%"
      height="100%"
      src={initializePaymentDetails.authorizationUrl}
      frameBorder="0"
      allow="clipboard-write; encrypted-media;"
      allowFullScreen
      title="Payment Payment portal"
      loading="lazy"
      aria-hidden="true"
      name="paymentPortal"
      id="paymentPortal"
      sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation"
      onLoad={onIframeNavigation}
      data-cy="payment-portal"
      data-testid="paymentGateway-checkout"
    />
  )
}

export default PaymentPortalIFrame
