import { GET_FACILITY_UNIT_TYPE } from '../types'
import { IFacilityUnit } from '../../models/facility-unit.model'

type GetFacilityUnitAction = {
  type: string
  payload: IFacilityUnit
}

export type IFacilityUnitStateType = {
  facilityUnit: Record<string, unknown>
}

export const INITIAL_STATE: IFacilityUnitStateType = {
  facilityUnit: {},
}

const {
  GET_FACILITY_UNIT_SUCCESS,
  GET_FACILITY_UNIT_FAILURE,
  GET_FACILITY_UNIT_REQUEST,
} = GET_FACILITY_UNIT_TYPE

export default (state = INITIAL_STATE, action: GetFacilityUnitAction) => {
  switch (action.type) {
    case GET_FACILITY_UNIT_REQUEST:
      return { ...state, facilityUnit: {}, isLoading: true }
    case GET_FACILITY_UNIT_SUCCESS:
      return { ...state, facilityUnit: action.payload, isLoading: false }
    case GET_FACILITY_UNIT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
