export interface IVerifyPaymentOption {
  reference: string
  paymentProcessor: string
}

export interface IVerifyPayment {
  status: string
  reference: string
  paymentTransactionId: number
}

export enum TransactionStatus {
  pending = 'pending',
  failed = 'failed',
  completed = 'completed',
  timedOut = 'timedOut',
  serverError = 'serverError',
}

export enum PaymentGatewayVerifyStatus {
  cancelled = 'cancelled',
  success = 'success',
  successful = 'successful',
  failed = 'failed',
  pending = 'pending',
}
