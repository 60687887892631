import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useStateIfMounted } from 'use-state-if-mounted'

import { localStorageService } from '../services'

import { KeyStorage } from '../models'
import Loader from '../components/Loader'
import { useAuth0 } from '../hooks'

interface IProtectedRouteProps {
  component: React.FC | any
  path: string
  exact: boolean
}

const ProtectedRoute = ({ component, path, exact }: IProtectedRouteProps) => {
  const [authenticated, setAuthenticated] = useStateIfMounted<undefined | boolean>(undefined)
  const [authenticatedError, setAuthenticationError] = useStateIfMounted<boolean>(false)

  const auth0 = useAuth0()
  const Component = component

  const checkAuth = async () => {
    try {
      if (!(window as any).Cypress) {
        const accessToken = await auth0.getAccessToken()
        localStorageService().store(KeyStorage.token, accessToken)
      }

      const isAuthenticated = (window as any).Cypress ? true : await auth0.isAuthenticated()
      localStorageService().store(KeyStorage.isAuthenticated, isAuthenticated)
      setAuthenticated(isAuthenticated)
    } catch (err) {
      setAuthenticationError(true)
    }
  }

  useEffect(() => {
    checkAuth()
  }, [authenticated])

  if (!(window as any).Cypress && authenticatedError) {
    localStorage.clear()
    window.location.assign('/?autherror=true')
  }

  if (authenticated === undefined) {
    return <Loader />
  }

  const isAuthenticated = localStorageService().get(KeyStorage.isAuthenticated)

  const handleRedirect = () => {
    auth0.logout({ unauthorized: true })
    return <Redirect to={{ pathname: '/' }} />
  }

  return isAuthenticated ? (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} isAuthenticated={authenticated} />}
    />
  ) : (
    handleRedirect()
  )
}

export default ProtectedRoute
