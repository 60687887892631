import { GET_USER_TYPE } from '../types'
import { IUser } from '../../models/user.model'

type GetUserAction = {
  type: string
  payload: IUser
}

export type IUserStateType = {
  isLoading: boolean
  userDetails: IUser
}

export const INITIAL_STATE: IUserStateType = {
  isLoading: false,
  userDetails: {},
}

const { GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE } = GET_USER_TYPE

export default (state = INITIAL_STATE, action: GetUserAction) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { ...state, userDetails: {}, isLoading: true }
    case GET_USER_SUCCESS:
      return { ...state, userDetails: action.payload, isLoading: false }
    case GET_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
