import React, { ReactElement } from 'react'
import { Button } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons'

import './CustomButton.styles.scss'

interface ICustomButton {
  disabled?: boolean
  bgColor: string
  color: string
  shadow?: boolean
  text: string
  onClick: any
  id?: string
  style?: any
  isLoading?: boolean
  cssClass?: string
}

const CustomButton = ({
  disabled = false,
  bgColor,
  color = 'purple',
  shadow = false,
  text,
  onClick,
  id,
  style,
  isLoading,
  cssClass,
}: ICustomButton): ReactElement => {
  const buttonStyle = (bgColorVal: string, colorVal: string, shadowVal: boolean): string => {
    if (bgColorVal === 'yellow' && colorVal === 'purple' && shadowVal === false)
      return 'yellow-button'

    if (bgColorVal === 'purple' && colorVal === 'purple' && shadowVal === false)
      return 'purple-button'
    if (bgColorVal === 'purple' && colorVal === 'white' && shadowVal === false)
      return 'purple-white-button'
    if (bgColorVal === 'yellow' && colorVal === 'white' && shadowVal === true)
      return 'yellow-button-shadow-white-color'
    return 'plain-button'
  }
  return (
    <Button
      type="primary"
      className={`${buttonStyle(bgColor, color, shadow)} ${cssClass}`}
      disabled={disabled}
      onClick={onClick}
      id={id}
      icon={isLoading ? <PoweroffOutlined data-testid="loader-component" /> : null}
      loading={isLoading}
      style={{ ...style }}
    >
      {text}
    </Button>
  )
}

export default CustomButton
