import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from '../components/Header'
import LandingPage from '../pages/LandingPage'
import Dashboard from '../pages/Dashboard'
import ProtectedRoute from '../hocs/ProtectedRoute'
import AuthCallback from '../pages/AuthCallback'
import PaymentCallback from '../pages/PaymentCallback'

const Routes = (): JSX.Element => (
  <Router>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <ProtectedRoute path="/dashboard" exact component={Dashboard} />
      <ProtectedRoute path="/auth/callback" exact component={AuthCallback} />
      <Route path="/payment/callback" exact component={PaymentCallback} />
      <Route component={LandingPage} />
    </Switch>
  </Router>
)

export default Routes
