import { TRANSFER_PAYMENT_TYPE } from '../types'

type TransferPaymentAction = {
  type: string
  payload: any
}

export type ITransferPaymentType = {
  transferPaymentResponse: any
}

export const INITIAL_STATE: ITransferPaymentType = {
  transferPaymentResponse: {},
}

const {
  TRANSFER_PAYMENT_SUCCESS,
  TRANSFER_PAYMENT_REQUEST,
  TRANSFER_PAYMENT_FAILURE,
} = TRANSFER_PAYMENT_TYPE

export default (state = INITIAL_STATE, action: TransferPaymentAction) => {
  switch (action.type) {
    case TRANSFER_PAYMENT_REQUEST:
      return { ...state, transferPaymentResponse: {}, isLoading: true }
    case TRANSFER_PAYMENT_SUCCESS:
      return { ...state, transferPaymentResponse: action.payload, isLoading: false }
    case TRANSFER_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
