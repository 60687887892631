import React, { ReactElement, useEffect } from 'react'
import { Row, Col, Typography, Input, Form, Alert, Select } from 'antd'

import './Dashboard.styles.scss'
import { IUser } from '../../models/user.model'
import { IResidentUserRole } from '../../models/facility-unit.model'
import { localStorageService } from '../../services'
import { KeyStorage } from '../../models'
import { formatFacilityUnitName } from '../../utils'
import PayeeDetailsLoader from '../../components/Loader/PayeeDetailsLoader'

interface IPayeeDetailsPaymentAmountProps {
  userData: IUser
  handleMeterChange: any
  currentFacilityUnitResidentUserRole: any
  currentMeterNumber: string
  setCurrentMeterNumber: any
  setCurrentFacilityUnit: any
}

const PayeeDetailsPaymentAmount: React.FC<IPayeeDetailsPaymentAmountProps> = ({
  userData,
  handleMeterChange,
  currentFacilityUnitResidentUserRole,
  currentMeterNumber,
  setCurrentMeterNumber,
  setCurrentFacilityUnit,
}: IPayeeDetailsPaymentAmountProps): ReactElement => {
  const { Title } = Typography
  const { Option } = Select

  const { firstName, lastName, email, residentUserRoles } = userData

  useEffect(() => {
    const currentFacilityUnitUserRoleFromStorage = localStorageService().get(
      KeyStorage.activeFacilityUnitUserRole,
    )

    const currentMeterNumberFromStorage =
      currentFacilityUnitUserRoleFromStorage?.facilityUnit?.meter?.displayedId || ''

    if (Object.keys(userData).length > 0) {
      setCurrentFacilityUnit(currentFacilityUnitUserRoleFromStorage)
      setCurrentMeterNumber(currentMeterNumberFromStorage)
    }
  }, [currentMeterNumber, Object.keys(userData).length])

  const meterChange = (value: string) => {
    handleMeterChange(value, residentUserRoles)
  }

  const facilityName =
    currentFacilityUnitResidentUserRole &&
    Object.keys(currentFacilityUnitResidentUserRole).length > 0
      ? formatFacilityUnitName(currentFacilityUnitResidentUserRole)
      : ''

  return (
    <>
      <Title
        level={4}
        className="header-text"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        Payee Details
      </Title>
      {Object.keys(userData).length === 0 ? (
        <PayeeDetailsLoader />
      ) : (
        <Form layout="vertical" className="form-div">
          <Input.Group size="large">
            <Row gutter={8}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Form.Item label="First Name" className="form-item">
                  <Input
                    type="text"
                    className="input-style-disabled"
                    disabled
                    value={firstName}
                    id="firstname"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{ span: 10, offset: 2 }} lg={10} xl={10}>
                <Form.Item label="Last Name" className="form-item-offset">
                  <Input
                    type="text"
                    className="input-style-disabled"
                    disabled
                    value={lastName}
                    id="lastname"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Input.Group size="large">
            <Row gutter={18}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Form.Item label="Email Address">
                  <Input
                    className="input-style-disabled"
                    type="email"
                    disabled
                    value={email}
                    id="email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{ span: 12 }} lg={12} xl={12}>
                <Alert
                  showIcon={false}
                  type="error"
                  message="This is where we’ll send your payment confirmation"
                  banner
                  className="alert-div"
                />
              </Col>
            </Row>
          </Input.Group>

          <Input.Group size="large">
            <Row gutter={12}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Form.Item label="Meter No.">
                  <Select
                    defaultValue="32542245"
                    value={currentMeterNumber}
                    style={{ width: '100%' }}
                    onChange={meterChange}
                  >
                    {residentUserRoles &&
                      residentUserRoles.length > 0 &&
                      residentUserRoles
                        .filter(
                          (residentUserRole: IResidentUserRole) =>
                            !!residentUserRole?.facilityUnit?.meter,
                        )
                        .map((residentUserRole: IResidentUserRole) => {
                          const {
                            meter: { displayedId },
                          } = residentUserRole.facilityUnit
                          return (
                            <Option key={residentUserRole?.id} value={displayedId}>
                              {displayedId}
                            </Option>
                          )
                        })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{ span: 10, offset: 2 }} lg={10} xl={10}>
                <Form.Item label="Property" className="form-item-offset">
                  <Input
                    type="text"
                    className="input-style-disabled"
                    disabled
                    value={facilityName}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form>
      )}
    </>
  )
}

export default PayeeDetailsPaymentAmount
